<template>
  <div class="columns is-centered mt-4">
    <div class="column is-one-third m-2">
        <h1>Impressum</h1>

        <h2>Angaben gem&auml;&szlig; &sect; 5 TMG</h2>
        <p>David Pogorzelski<br />
        Danckelmannstra&szlig;e 46<br />
        14059 Berlin</p>

        <h2>Kontakt</h2>
        <p>Telefon: 0176 59775073<br />
        E-Mail: me@davidpogorzel.ski</p>

        <p>Quelle: <a href="https://www.e-recht24.de/impressum-generator.html">https://www.e-recht24.de/impressum-generator.html</a></p>
    </div>
</div>
</template>

<script>
// @ is an alias to /src

export default {
  name: "Impressum"
}

</script>